import "./Limited.scss";
import "~/Src/EntryPoints/Shared/Default";
import LsFaceIdLogin from "~/Src/Components/FaceIdLogin/FaceIdLogin";
import LsLogger from "~/Src/Components/Logging/Logger";

$(document).ready(() => {
    const goNative = $("#goNativeCheck").data("gonative");
    const setting = $("#settingCheck").data("enabled");

    console.log(goNative);
    console.log(setting);

    if (goNative === "True" && setting === "True" || goNative === true && setting === true) {
        LsLogger.log(`GoNative and setting check were true`, "info");

        LsFaceIdLogin.get();
    }
});